import React from "react";
import "./styles/Footer.css";

function Footer() {
  return (
    <div className="footer" id="Footer">
      <div className="f1">
        <div className="ft1">
          <svg
            width="170"
            height="70"
            viewBox="0 0 409 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3857 0.904204C27.859 0.904204 33.8286 6.35463 33.8286 14.7249C33.8286 23.1601 27.7942 28.6105 18.3857 28.6105H8.84744V46H0.542022V0.904204H18.3857ZM17.9964 21.3433C22.8628 21.3433 25.3934 18.6181 25.3934 14.7249C25.3934 10.8967 22.8628 8.17144 17.9964 8.17144H8.84744V21.3433H17.9964ZM48.1339 38.603H69.4165V46H39.8285V0.904204H48.1339V38.603ZM103.616 46L99.9819 35.2938H82.3978L78.7642 46H70.5236L86.0963 0.904204H96.1536L111.791 46H103.616ZM84.7337 28.4808H97.646L91.1574 9.59893L84.7337 28.4808ZM144.011 0.904204H152.251V46H145.373L124.804 15.1791V46H116.564V0.904204H123.442L144.011 31.79V0.904204ZM190.692 8.10655H169.668V19.6563H188.356V26.9235H169.668V46H161.363V0.904204H190.692V8.10655ZM217.239 46.7786C204.132 46.7786 194.009 36.7862 194.009 23.4845C194.009 10.118 204.132 0.125574 217.239 0.125574C230.41 0.125574 240.533 10.118 240.533 23.4845C240.533 36.7862 230.41 46.7786 217.239 46.7786ZM217.239 38.9274C225.998 38.9274 232.097 32.5037 232.097 23.4197C232.097 14.4005 226.128 7.97678 217.239 7.97678C208.414 7.97678 202.445 14.4005 202.445 23.4197C202.445 32.5037 208.479 38.9274 217.239 38.9274ZM272.005 46L258.249 26.9884H255.654V46H247.413V0.904204H265.257C274.73 0.904204 280.7 6.35463 280.7 13.9463C280.7 21.0189 275.444 26.2746 267.138 26.9235L281.284 46H272.005ZM255.654 8.17144V21.0838H265.062C269.864 21.0838 272.524 18.4883 272.524 14.5952C272.524 10.7669 269.864 8.17144 265.062 8.17144H255.654ZM330.556 22.0571C330.556 37.6946 320.694 46.7786 307.911 46.7786C294.61 46.7786 284.877 36.9808 284.877 23.4845C284.877 9.92336 294.674 0.125574 307.846 0.125574C318.812 0.125574 325.885 6.35463 328.675 13.8165L320.759 17.1257C318.747 11.3509 314.27 7.97678 307.717 7.97678C299.152 7.97678 293.312 14.2707 293.312 23.6143C293.312 33.0228 299.216 39.3167 308.625 39.3167C315.698 39.3167 320.759 35.748 322.511 28.351H307.652V22.0571H330.556ZM345.444 38.7976H367.051V46H337.138V0.904204H366.661V8.10655H345.444V19.3318H364.39V26.5342H345.444V38.7976Z"
              fill="#080047"
            />
            <path
              d="M401.079 13.7045L395.291 0.636363H397.634L401.683 10.2244L405.731 0.636363H408.075L402.286 13.7045H401.079ZM394.865 13.7045V0.636363H397.137V13.7045H394.865ZM406.228 13.7045V0.636363H408.501V13.7045H406.228ZM386.342 13.7045V1.77273H388.615V13.7045H386.342ZM382.365 2.625V0.636363H392.592V2.625H382.365Z"
              fill="#080047"
            />
          </svg>
        </div>
        <div className="ft2">
          <a href="#Home" className="option">
            HOME
          </a>
          <a href="#About" className="option">
            ABOUT
          </a>
          <a href="#Services" className="option">
            SERVICES
          </a>
          <a href="#Projects" className="option">
            PROJECTS & SAMPLES
          </a>
          <a href="#Contact" className="option">
            CONTACT
          </a>
        </div>
      </div>
      <div className="f2">
        <p>
          © FARCAST 2024. All rights reserved. Your privacy is important to us.
          We do not share or sell your personal information to third parties.
          For more details, please read our Privacy Policy. By using our
          services, you agree to our Terms of Service. Please review the terms
          to understand the guidelines for using our website and services.
          <br />
          <br />
          Remember to link "Privacy Policy" and "Terms of Service" to their
          respective pages on your website for users to access the full
          information.
        </p>
      </div>
    </div>
  );
}

export default Footer;
