import React from "react";
import "./styles/Projects.css";

function Projects() {
  let openInNewTab = (file) => {
    window.open(file, "_blank");
  };

  return (
    <div className="projects" id="Projects">
      <div className="card" id="card1">
        <div className="cardcon">
          <div className="cardinfo">
            <h3>Travel Agency</h3>
            <p>
              Completed a feasibility study, offering detailed insights into
              market potential, financial viability, and operational strategies
              for an online travel agency.
            </p>
          </div>
          <div className="cardbtn">
            <button
              onClick={() =>
                openInNewTab(
                  require("./assets/projects/Travel-Feasibility.pdf")
                )
              }
            >
              View Sample
            </button>
          </div>
        </div>
      </div>
      <div className="card" id="card2">
        <div className="cardcon">
          <div className="cardinfo">
            <h3>Crypto Currency Exchange</h3>
            <p>
              Completed a feasibility study, offering detailed insights into
              market potential, financial viability, and operational strategies
              for a crypto exchange.
            </p>
          </div>
          <div className="cardbtn">
            <button
              onClick={() =>
                openInNewTab(
                  require("./assets/projects/Crypto-Feasibility.pdf")
                )
              }
            >
              View Sample
            </button>
          </div>
        </div>
      </div>
      <div className="card" id="card3">
        <div className="cardcon">
          <div className="cardinfo">
            <h3>Our Article</h3>
            <p>
              Check out our latest article for the latest insights and trends.
            </p>
          </div>
          <div className="cardbtn">
            <button
              onClick={() =>
                openInNewTab(require("./assets/projects/Article.pdf"))
              }
            >
              View Sample
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
