import React from "react";
import "./styles/Contact.css";

function Contact() {
  return (
    <div className="contact" id="Contact">
      <div className="con1">
        <form action="https://formspree.io/f/meqwbgno" method="POST">
          <div className="form1">
            <input
              type="text"
              name="Full Name"
              id=""
              placeholder="Full Name*"
            />
            <input type="tel" name="Phone" id="" placeholder="Phone" />
          </div>
          <input
            type="text"
            placeholder="Subject*"
            name="Subject"
            className="input"
            required
          />
          <textarea
            name="Message"
            id=""
            required
            placeholder="Message*"
          ></textarea>
          <button>SEND</button>
        </form>
      </div>
      <div className="img"></div>
    </div>
  );
}

export default Contact;
