import React from "react";
import "./styles/About.css";

function About() {
  return (
    <div className="about" id="Team">
      <div className="abt">
        <div className="pic">
          <img src={require("../components/assets/images/samia.jpg")} alt="" />
        </div>
        <div className="abtt">
          <h3>Samia Al Kaakour</h3>
          <p>Co-founder / Economic Consultant</p>
        </div>
        <p>
          Samia Al Kaakour is an economic consultant and the co-founder of
          PLANFORGE Economic Consultancy. She is a PhD candidate, Université
          Saint Joseph (USJ)-Faculté des sciences économiques (FSE). With
          extensive experience in quantitative analysis, Samia has contributed
          significantly to numerous research projects, including collaborations
          with UNESCO and ministry of education (MEHE) and other academic
          institutions. <br />
          <br /> Her expertise encompasses econometric modeling, data analysis,
          and tutoring in the use of statistical software, making her a
          versatile and knowledgeable professional in the field of economics.
          Samia's dedication to her work and her commitment to advancing
          economic research have established her as a respected figure in the
          industry.
        </p>
      </div>
      <div className="divider"></div>
      <div className="abt">
        <div className="pic">
          <img
            src={require("../components/assets/images/oussama.jpg")}
            alt=""
          />
        </div>
        <div className="abtt">
          <h3>Oussama Dimashki</h3>
          <p>Co-founder / Chief Economist</p>
        </div>
        <p>
          Oussama Dimashki is the Co-founder of PLANFORGE. Economic Consultancy Master's degree (M1) in
          Economic Sciences from the Lebanese University, Faculty of Economics
          and Business Administration. Specializes in providing comprehensive
          economic consultancy services Strong academic background and expertise
          in economic analysis. <br /><br /> Actively pursues higher education and engages in
          economic research. Possesses analytical skills and strategic thinking
          . Valuable asset in developing tailored business strategies and
          conducting detailed economic analyses. Experienced in teaching
          economics and sociology at the secondary level for 25 years.
        </p>
      </div>
      <div className="divider"></div>
      <div className="abt">
        <div className="pic">
          <img
            src={require("../components/assets/images/soumaya.jpg")}
            alt=""
          />
        </div>
        <div className="abtt">
          <h3>Soumaya Al Kaakour</h3>
          <p>Marketing Specialist</p>
        </div>
        <p>
          Dr. Soumaya Al Kaakour is a distinguished expert in Marketing and
          Business Administration, holding a PhD from Beirut Arabic University.
          She serves as a respected educator at Beirut Arab University and the
          Arts, Sciences, and Technology University in Lebanon. With over ten
          years of experience at Credit Libanais SAL, she has excelled in
          customer relations and e-payment solutions. <br />
          <br /> Dr. Al Kaakour is a prolific researcher. Certified in AI for
          educators, she is also actively engaged in community service,
          demonstrating a commitment to both academic excellence and societal
          advancement.
        </p>
      </div>
    </div>
  );
}

export default About;
