import React from "react";
import "./styles/Services.css";

function Services() {
  return (
    <div className="services" id="Services">
      <h1>Services We Provide</h1>
      <div className="serviceList">
        <div className="service">
          <h3>Economic Feasibility Studies</h3>
          <p>
            We perform comprehensive economic feasibility studies to assess the potential success of projects. Our evaluations include in-depth economic projections, cost-benefit analyses, and economic modeling to ensure that your projects are economically viable and sustainable.
          </p>
        </div>
        <div className="service">
          <h3>Business Plan Development</h3>
          <p>
            We assist in crafting robust business plans that outline your
            business objectives, strategies, and financial projections. Our
            business plans serve as a comprehensive roadmap to guide your
            business towards achieving its goals and securing funding from
            investors.
          </p>
        </div>
        <div className="service">
          <h3>Econometric modeling and data analysis</h3>
          <p>
            At our consultancy, we provide advanced econometric analysis to help
            you understand complex economic relationships and make data-driven
            decisions. Our services include developing statistical models,
            forecasting trends, and interpreting complex datasets to derive
            actionable insights, all tailored to meet your specific needs and
            objectives.
          </p>
        </div>
        <div className="service">
          <h3>Training Programs</h3>
          <p>
            Our specialized training programs are designed to enhance the skills
            and knowledge of your team in key areas such as econometrics,
            leadership management, strategic marketing and entrepreneurial
            skills. We offer customized training sessions that cater to the
            unique needs of your organization, empowering your team to excel in
            their roles.
          </p>
        </div>
        <div className="service">
          <h3>Market Research and Analysis</h3>
          <p>
            Our comprehensive market research and analysis services provide
            detailed insights into market trends, consumer behavior, and
            competitive landscapes. This enables clients to make informed
            decisions, identify growth opportunities, and develop effective
            market strategies.
          </p>
        </div>
        <div className="service">
          <h3>Policy advisory</h3>
          <p>
            Understanding the impact of existing and proposed regulations on
            operations, creating effective policy recommendations and compliance
            strategies, facilitating communication with government agencies,
            industry groups, and the public and evaluating the potential effects
            of policy changes on business performance and efficiency.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
